import React from 'react'
import Card from '../components/Card'
import Grid from '../components/Grid'
import Header from '../components/Header'
import Layout from '../components/layout'
import Wrapper from '../styles/Wrapper'
import { graphql } from 'gatsby'

export default ({ data }) => (
  <Layout title="Recepten" content="Als ik kook, kook ik graag iets wat ik écht lekker vind. Natuurlijk komt er nog wel eens een nieuwe creatie mijn keuken uit waar ik niet super tevreden over ben, maar dat is allemaal onderdeel van de zoektocht naar de beste recepten.">
    <Header />

    <Wrapper>
      <h1 css={{ textAlign: 'center' }}>Recepten</h1>

      <p
        css={{
          margin: '0 auto 4rem',
          maxWidth: 840,
          textAlign: 'center',
        }}
      >
        Als ik kook, kook ik graag iets wat ik écht lekker vind. Natuurlijk komt er nog wel eens een nieuwe creatie mijn keuken uit waar ik niet super tevreden over ben, maar dat is allemaal onderdeel van de zoektocht naar de beste recepten. Soms bedenk ik iets zelf, soms pas ik een recept van een andere blog naar mijn eigen smaak aan. En omdat ik vaak voor andere mensen kook, heb ik al gauw in de gaten of het recept een <em>crowd pleaser</em> is of niet. En hier deel ik alleen maar de crowd pleasers!
      </p>

      <Grid>
        {data.allMarkdownRemark.edges.map(article => (
          <Card
            key={article.node.frontmatter.path}
            title={article.node.frontmatter.title}
            slug={article.node.frontmatter.path}
            image={article.node.frontmatter.image}
          />
        ))}
      </Grid>
    </Wrapper>
  </Layout>
)

export const query = graphql`
  query Recipes {
    allMarkdownRemark(
      filter: { frontmatter: { path: { regex: "/recepten/.+/" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            image {
              childImageSharp {
                fluid(maxWidth: 760) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
